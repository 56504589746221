interface UnknownErrorComponentProps {
    showBugButton?: boolean;
    showGoHomeButton?: boolean;
    className?: string;
}

export default function UnknownErrorComponent({showBugButton = true, showGoHomeButton = true, className }: UnknownErrorComponentProps) {
    const targetClassName = className ?? 'flex items-center justify-center w-screen h-screen bg-gradient-to-r from-primary to-primary-dark md:px-20 p-4';
    return <div
        className={targetClassName}
    >
        <div className="md:px-40 px-5 md:py-20 py-5 bg-white rounded-md shadow-xl">
            <div className="flex flex-col items-center">
                <h6 className="mb-2 text-2xl font-bold text-center text-gray md:text-3xl">
                    <span className="text-red">Oops!</span> Don't know how we got here!
                </h6>

                <p className="mb-8 text-center text-gray md:text-lg">
                It seems you've hit an unexpected crux on Climbit. Don't worry, we're still in the beta phase, addressing these unforeseen challenges as they come. Our team is actively working to resolve this issue. Thanks for your patience and for being part of our journey!
                </p>
                <div className="flex flex-row space-x-2 md:space-x-4">
                    {showBugButton && (
                        <a
                            href="/bug"
                            className="px-6 py-2 text-sm font-semibold text-white bg-primary hover:bg-primary-dark"
                        >Report Bug
                        </a>
                    )}
                    {showGoHomeButton && (
                        <a
                            href="/"
                            className="px-6 py-2 text-sm font-semibold text-white bg-primary hover:bg-primary-dark"
                        >Go Home
                        </a>
                    )}
                </div> 
            </div>
        </div>
    </div>;

}



