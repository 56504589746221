import { WiRaindrop, WiStrongWind, WiCloudy, WiHumidity } from 'react-icons/wi';
import { FaHandHoldingWater } from 'react-icons/fa';
import { getDisplayPrecipitation } from '../../utils/WeatherUtils';
import { weatherCodesMap } from '../Forecast/WeatherIcons';
import { HourlyForecast } from '../../types/Forecast';
import './HourlyForecast.css';
import StarsComponent from '../common/Stars';
import { useRecoilValue } from 'recoil';
import { unitPreferencesState, precipUnitSelector, speedUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';
import { FaSnowflake } from 'react-icons/fa6';

type HourlyForecastCardProps = {
    forecast: HourlyForecast,
    card?:any
    displayAreaName?: boolean;
    isBestTimeToClimb?: boolean;
    className?: string;
    currentWeather?: boolean;
    overImage?: boolean;
  }


export default function HourlyForecastCardComponent({ forecast, card, displayAreaName=false, isBestTimeToClimb=false, className='', currentWeather=false, overImage=false }: HourlyForecastCardProps) {
    const temperatureUnit = useRecoilValue(temperatureUnitSelector);
    const speedUnit = useRecoilValue(speedUnitSelector);
    const precipUnit = useRecoilValue(precipUnitSelector);
    const {metricUnits} = useRecoilValue(unitPreferencesState);
    const isNightClass = !forecast.isDay ? 'bg-gray-light' : '';
    const dependentClass = overImage ? 'hourly-over-image' : 'card-layout';
    const isBestTimeToClimbClass = isBestTimeToClimb ? 'border-4 border-orange border-opacity-75' : '';
    const hasMultiplePrecipTypes = (forecast.rainfall > 0 && forecast.snowfall > 0);
    return (
        <div ref={card} className={`flex flex-col justify-between ${dependentClass} h-full ${className} ${isNightClass} ${isBestTimeToClimbClass} min-w-[15rem]` } key={`hourly-forecast-${forecast.time}`}>
            {displayAreaName && forecast.areaName && (
                <div className='card-title flex items-center'>
                    <span className='line-clamp-2 block'>{forecast.areaName}</span>
                </div>
            )}
           
            <div>
                <span className="card-time">{currentWeather ? 'Current Weather' : forecast.displayTime}</span>
            </div>

            <div>
                {forecast.weatherCode !== undefined && (
                    <div className="weather-icon-position">
                        {weatherCodesMap(forecast.isDay).get(forecast.weatherCode.toString())}
                    </div>
                )}
                <p className="weather-description">{forecast.weatherCodeDescription}</p>
            </div>

            <div>
                <div className={`${currentWeather ? 'mt-3' : '' } temperature`}>
                    {forecast.temperature}{temperatureUnit}
                </div>
                <div className="feels-like-heading">
                    Feels Like {forecast.apparentTemperature}{temperatureUnit}
                </div>
            </div>

            <div className='h-[90px] w-full flex flex-col justify-center'>
                {hasMultiplePrecipTypes && (
                    <div className='w-full flex flex-col'>
                        <div className={`flex justify-between px-3 rain-wind-hum-cloud-score-div w-full ${currentWeather ? 'mt-1' : '' }`}>
                            <div className="icons-styling-hourly">
                                <WiCloudy size={30}/>
                                {forecast.cloudCover}%
                            </div>
                            {forecast.precipitationProbability !== null && (
                                <div className="icons-styling-hourly">
                                    <WiHumidity size={30}/>
                                    {forecast.precipitationProbability}%
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between px-3 rain-wind-hum-cloud-score-div">
                            <div className="icons-styling-hourly">
                                <WiRaindrop size={15} className='scale-[2] mr-1'/>
                                {getDisplayPrecipitation(forecast.rainfall, metricUnits)} {precipUnit}
                            </div>
                            <div className="icons-styling-hourly">
                                <FaSnowflake size={15} className='mr-1'/>
                                {getDisplayPrecipitation(forecast.snowfall, metricUnits)} {metricUnits ? 'cm' : precipUnit}
                            </div>
                        </div>
                    </div>
                )}

                {!hasMultiplePrecipTypes && (
                    <div className='w-full'>
                        <div className={`rain-wind-hum-cloud-score-div w-full ${currentWeather ? 'mt-3' : '' }`}>
                            <div className="icons-styling-hourly">
                                <WiCloudy size={30}/>
                                {forecast.cloudCover}%
                            </div>
                            {forecast.precipitationProbability !== null && (
                                <div className="icons-styling-hourly">
                                    <WiHumidity size={30}/>
                                    {forecast.precipitationProbability}%
                                </div>
                            )}
                            {forecast.rainfall > 0 || forecast.snowfall === 0 && (
                                <div className="icons-styling-hourly">
                                    <WiRaindrop size={30}/>
                                    {getDisplayPrecipitation(forecast.rainfall, metricUnits)}{precipUnit}
                                </div>
                            )}
                            {forecast.snowfall > 0  && (
                                <div className="icons-styling-hourly">
                                    <FaSnowflake size={15} className='mr-1'/>
                                    {getDisplayPrecipitation(forecast.snowfall, metricUnits)}{metricUnits ? 'cm' : precipUnit}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={'flex flex-row justify-between px-3 rain-wind-hum-cloud-score-div w-full'}>
                    <div className="icons-styling-hourly">
                        <FaHandHoldingWater size={20}/>
                        &nbsp;{forecast.relativeHumidity}%
                    </div>
                    <div className="icons-styling-hourly">
                        <WiStrongWind size={30}/>
                        {forecast.windSpeed} {speedUnit} {forecast.windDirection}
                    </div>
                </div>
            </div>
            <div className={currentWeather ? 'mt-3' : '' }>
                {forecast.climbit && forecast.climbit.climbitStars !== undefined && (              
                    <span>
                        <StarsComponent value={forecast.climbit.climbitStars} max={5}/>
                    </span>                    
                )}
            </div>
        </div>
    );
}
