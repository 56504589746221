/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HourlyForecast as HourlyForecastType } from '../../types/Forecast';
import HourlyForecastCharts from './HourlyForecastCharts';
import HourlyForecastTable from './HourlyForecastTable';
import CardCarousel from '../cards/CardCarousel';
import { selectedViewState } from '../../state/areasState';
import { useRecoilState } from 'recoil';

type HourlyForecastsProps = {
    forecasts: HourlyForecastType[],
    bestTimeToClimb?: HourlyForecastType,
    sunrise?: Date,
    sunset?: Date,
    weeklyHourlyForecast: HourlyForecastType[][],
    showWeeklyForecast: boolean,
    isMetric?: boolean
}

const HourlyForecasts = ({ forecasts, bestTimeToClimb, sunrise, sunset, weeklyHourlyForecast, showWeeklyForecast, isMetric }: HourlyForecastsProps) => {
    const [selectedView, setSelectedView] = useRecoilState(selectedViewState);
    
    return (
        <div>   
            <div>
                <div className='w-full justify-center md:justify-end flex'>
                    <select id="views" value={selectedView}  onChange={e => setSelectedView(e.target.value)} className="flex border border-gray text-gray text-sm rounded-lg appearance-none m-4 focus:ring-primary focus:border-primary md:mr-10">
                        <option value="table">Table View</option>
                        <option value="graph">Graph View</option>
                        <option value="card">Card View</option>
                    </select>
                </div>
                {selectedView === 'graph' && (
                    <HourlyForecastCharts
                        forecasts={forecasts}
                        weeklyHourlyForecast={weeklyHourlyForecast}
                        showWeeklyForecast={showWeeklyForecast}
                        isMetric={isMetric}
                    />
                )}
                { selectedView === 'card' && (
                    <CardCarousel hourlyForecasts={forecasts} bestTimesToClimb={bestTimeToClimb ? [bestTimeToClimb] : undefined} />
                )}
                { selectedView === 'table' && (
                    <HourlyForecastTable
                        forecasts={forecasts}
                        bestTimeToClimb={bestTimeToClimb}
                        sunrise={sunrise}
                        sunset={sunset}
                        isMetric={isMetric}
                    />
                )}
            </div>
        </div>
    );
};

export default HourlyForecasts;