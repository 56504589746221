import { ForecastMinMaxValues, HourlyForecast } from '../../types/Forecast';
import TemperatureChart from '../charts/TemperatureChart';
import RelativeHumidityChart from '../charts/RelativeHumidityChart';
import PrecipitationProbabilityChart from '../charts/PrecipitationProbabilityChart';
import ActionsButton from './Actions';
import CloudCoverChart from '../charts/CloudCoverChart';
import WindSpeedChart from '../charts/WindSpeedChart';
import ClimbitChart from '../charts/ClimbitChart';
import { PiChartLineBold } from 'react-icons/pi';
import { lineColors } from '../../utils/CommonUtils';
import PrecipitationAmountChart from '../charts/PrecipitationAmountChart';

type GraphSelectorProps = {
    hourlyForecasts: HourlyForecast[];
    setSelectedGraph: React.Dispatch<React.SetStateAction<string>>;
    selectedGraph: string;
    weeklyHourlyForecast?: HourlyForecast[][];
    title?: string;
    height?: string;
    isMetric?: boolean;
    showWeeklyForecast?: boolean;
    forecastMinMaxValues?: ForecastMinMaxValues;
    alignment?: string;
  }

export default function GraphSelector({ hourlyForecasts, setSelectedGraph, selectedGraph, title, height='h-[15rem]', isMetric, weeklyHourlyForecast, showWeeklyForecast=false, alignment='right-[16px] top-[16px]', forecastMinMaxValues }: GraphSelectorProps) {
    return (
        <div className='relative flex flex-col w-full py-1'>
            {title && (
                <div className='card-date text-lg text-center flex items-center justify-center'>
                    <span className='max-w-[75%] whitespace-nowrap overflow-ellipsis overflow-hidden'>{title}</span>
                </div>

            )}
            <ActionsButton className='compare-actions-btn z-[5] py-1' icon={<PiChartLineBold size={20}/>} alignment={alignment} >
                <div className='cursor-pointer font-semibold'  onClick={() =>{ setSelectedGraph('climbit');}}>
                        Climbit Score
                </div>
                <div className='cursor-pointer font-semibold'  onClick={() =>{ setSelectedGraph('temperature');}}>
                        Temperature
                </div>
                <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('humidity');}}>
                        Humidity
                </div>
                <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('rainfall-amount');}}>
                        Rainfall Amount
                </div>
                {((forecastMinMaxValues && forecastMinMaxValues.maxSnowfall > 0) || weeklyHourlyForecast?.flat().some(hf => hf.snowfall > 0))  && (
                    <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('snowfall-amount');}}>
                        Snowfall Amount
                    </div>  
                )} 
                <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('precipitation-percent');}}>
                        Precip Probability
                </div>
                <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('cloud-cover');}}>
                        Cloud Cover
                </div>
                <div className='cursor-pointer font-semibold' onClick={() =>{ setSelectedGraph('wind');}}>
                        Wind
                </div>
            </ActionsButton>
            <div className=''>
                {selectedGraph === 'climbit' && (
                    <div key={'climbit-graph'} className='snap-normal snap-center flex'>
                        <ClimbitChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} shadow={false} padding='p-1' color={lineColors.climbitScoreLineColor} />
                    </div>
                )}
                {selectedGraph === 'temperature' && (
                    <div key={'temp-graph'} className='snap-normal snap-center'>
                        <TemperatureChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric} showTitle={false} height={height} shadow={false} padding='p-1' yMin={forecastMinMaxValues ? Math.floor(Math.min(forecastMinMaxValues.minApparentTemperature, forecastMinMaxValues.minTemp) / 10) * 10 : undefined} yMax={forecastMinMaxValues ? Math.ceil(Math.max(forecastMinMaxValues.maxApparentTemperature, forecastMinMaxValues.maxTemp) / 10) * 10 : undefined} />
                    </div>
                )}
                {selectedGraph === 'humidity' && (
                    <div key={'relative-humidity-graph'} className='snap-normal snap-center'>
                        <RelativeHumidityChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} shadow={false} padding='p-1' />
                    </div>
                )}
                {selectedGraph === 'rainfall-amount' && (
                    <div key={'rainfall-amount-graph'} className='snap-normal snap-center'>
                        <PrecipitationAmountChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} isMetric={isMetric} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} shadow={false} padding='p-1' yMax={forecastMinMaxValues ? Math.ceil(forecastMinMaxValues.maxQuantitativePrecipitation * 10) / 10 : undefined} />
                    </div>
                )}
                
                {selectedGraph === 'snowfall-amount' && (
                    <div key={'snowfall-amount-graph'} className='snap-normal snap-center'>
                        <PrecipitationAmountChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} isMetric={isMetric} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} isSnow={true} shadow={false} padding='p-1' yMax={forecastMinMaxValues ? Math.ceil(forecastMinMaxValues.maxSnowfall * 10) / 10 : undefined} />
                    </div>
                )}
                {selectedGraph === 'precipitation-percent' && (
                    <div key={'precipitation-percent-graph'} className='snap-normal snap-center'>
                        <PrecipitationProbabilityChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} shadow={false} padding='p-1' />
                    </div>
                )}
                {selectedGraph === 'cloud-cover' && (
                    <div key={'cloud-cover-graph'} className='snap-normal snap-center'>
                        <CloudCoverChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} showTitle={false} height={height} shadow={false} padding='p-1' />
                    </div>
                )}
                {selectedGraph === 'wind' && (
                    <div key={'wind-graph'} className='snap-normal snap-center'>
                        <WindSpeedChart hourlyForecasts={hourlyForecasts} weeklyHourlyForecast={weeklyHourlyForecast} showWeeklyForecast={showWeeklyForecast} isMetric={isMetric} showTitle={false} height={height} shadow={false} padding='p-1' yMax={forecastMinMaxValues ? Math.ceil(forecastMinMaxValues.maxWindSpeed / 10) * 10 : undefined} />
                    </div>
                )}
            </div>
        </div>
    );
}
