import { Md5 } from 'ts-md5';
import { LineChartData } from '../types/LineChart';
import moment from 'moment';

export type GroupedObjects = { [key: string]: any[] };

export const cleanString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');


export const groupBy = (array: any[], prop: string): GroupedObjects => {
    return array.reduce((acc, curr) => {
        const key = curr[prop];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
    }, {} as GroupedObjects);
};

export const getValuesWithFilter = <T, K extends keyof T>(
    array: T[],
    property: K | string,
    filter: (obj: T) => boolean
): T[K][] => {
    return array
        .filter((obj) => filter(obj))
        .map((obj) => {
            const properties = property.toString().split('.');
            let value = obj as any;
            for (const prop of properties) {
                value = value[prop];
            }
            return value as T[K];
        });
};

export const createLineData = (
    labelDescription: string,
    colorKey: keyof LineColors,
    arr: any[],
    prop: string,
    cb: any = () => true
): LineChartData => {
    return {
        labelDescription,
        values: getValuesWithFilter(arr, prop, cb),
        color: lineColors[colorKey],
    };
};

interface LineColors {
  starsLineColor: string;
  tempLineColor: string;
  feelsLikeLineColor: string;
  relHumidityLineColor: string;
  precipitationLineColor: string;
  precipitationProbLineColor: string;
  cloudCoverLineColor: string;
  windSpeedLineColor: string;
  climbitScoreLineColor: string;
  snowLineColor: string;
}

export const lineColors: LineColors = {
    starsLineColor: 'hsl(242, 84%, 66%)', // purple
    tempLineColor: 'hsl(161, 59%, 52%)', //aqua (bluish green)
    feelsLikeLineColor: 'hsl(221, 92%, 57%)', // deep blue
    relHumidityLineColor: 'hsl(13, 84%, 84%)', // peach
    precipitationLineColor: 'hsl(187, 93%, 50%)', // light blue
    precipitationProbLineColor: 'hsl(200, 93%, 49%)', // sky blue
    snowLineColor: 'hsl(212, 99%, 42%)', // dark blue
    cloudCoverLineColor: 'hsl(213, 24%, 54%)', // grayish blue
    windSpeedLineColor: 'hsl(0, 100%, 60%)', // red
    climbitScoreLineColor: 'hsl(39, 100%, 50%)', // orange

};

interface CompareLineColors {
  color_1: string;
  color_2: string;
  color_3: string;
  color_4: string;
  color_5: string;
}


export const compareLineColors: CompareLineColors = {
    color_1: 'hsl(161, 59%, 52%)', //aqua (bluish green)
    color_2: 'hsl(221, 92%, 57%)', // deep blue
    color_3: 'hsl(13, 84%, 84%)', // peach
    color_4: 'hsl(187, 93%, 50%)', // light blue
    color_5: 'hsl(213, 24%, 54%)', // grayish blue
};

export const _getCompareLineData = (
    areas: any,
    prop1: string,
    prop2: string | null = null,
    selectedArea?: string,
    timeProp = 'displayTime',
    timeValues?: string[],
    color?: string,
    labelDescription?: string
) => {
    const colorKeys = Object.values(compareLineColors);
    let i = 0;
    const result = areas.reduce((acc: any, area: any) => {
        const existingEntry = acc.find(
            (entry: any) => entry.labelDescription === area.areaName || entry.labelDescription === labelDescription
        );

        if (existingEntry) {
            if (prop2) existingEntry.values.push(area[prop1][prop2]);
            else existingEntry.values.push(area[prop1]);
            
            if (timeValues) {
                existingEntry.times.push(area[timeProp]);
            }
        } else {
            if (prop2) {
                acc.push({
                    labelDescription: labelDescription ?? area.areaName,
                    values: [area[prop1][prop2]],
                    times: timeValues ? [area[timeProp]] : undefined,
                    color: color ?? colorKeys[i++],
                    hidden: selectedArea ? selectedArea !== area.areaName : false
                });
            } else {
                acc.push({
                    labelDescription: labelDescription ?? area.areaName,
                    values: [area[prop1]],
                    times: timeValues ? [area[timeProp]] : undefined,
                    color: color ?? colorKeys[i++],
                    hidden: selectedArea ? selectedArea !== area.areaName : false
                });
            }
        }
        return acc; 
    }, []);

    if (timeValues) {
        // Normalize data here
        const allTimes = timeValues.slice(); // Copy timeValues array
        result.forEach((entry: any) => {
            if (entry.times) {
                const normalizedValues: any[] = [];
                allTimes.forEach((time: string) => {
                    const index = entry.times.indexOf(time);
                    if (index !== -1) {
                        normalizedValues.push(entry.values[index]);
                    } else {
                        normalizedValues.push(undefined);
                    }
                });
                entry.values = normalizedValues;
                entry.times = allTimes;
            }
        });
    }
    return result;
};

export const createUniqueLabels = (items: any, itemProperty: string)  => {
    const uniqueLabels: string[] = Array.from(new Set(items.map((item: any) => item[itemProperty])));
    return uniqueLabels;
};

export const createUniqueId = (latitude: number, longitude: number) => {
    const latLngString = `${latitude.toFixed(6)},${longitude.toFixed(6)}`;
    return Md5.hashStr(JSON.stringify(latLngString));
};

export const sortDisplayDates = (displayDates: string[]) => {
    // Custom sorting function
    return displayDates.sort((a, b) => {
        // Parse time strings using Moment.js
        const timeA = moment(a, 'h A');
        const timeB = moment(b, 'h A');
    
        // Compare moment objects
        return timeA.diff(timeB);
    });
};

export function isValueWithinHour(value: Date, hour: Date): boolean {
    const hourStartTime = new Date(hour);
    const hourEndTime = new Date(hour);
    
    // Set hourEndTime to end of the hour
    hourEndTime.setMinutes(59);
    hourEndTime.setSeconds(59);

    return value >= hourStartTime && value <= hourEndTime;
}

export function createUrlSubdomain(value: string): string {
    return value.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();
}

export function undoUrlSubdomain(value: string): string {
    return value
        .split('-') // Split the string by hyphens
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(' '); // Join the words back with spaces
}

export function isIosApp() {
    const userAgent = window.navigator.userAgent;
    const isIos = /iPhone|iPad|iPod/i.test(userAgent);
    const isPWAShell = /PWAShell/i.test(userAgent);
    return isIos && isPWAShell;
}

export function isAndroidApp() {
    const userAgent = window.navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    return isAndroid && isStandalone;
}

export function isMobileBrowser() {
    const userAgent = window.navigator.userAgent;
    return /Mobi|Android/i.test(userAgent);
}



