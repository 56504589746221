// import { useState } from 'react';
import { WiRaindrop } from 'react-icons/wi';
import {
    BsThermometerHigh,
    BsThermometerLow
} from 'react-icons/bs';
import { getDisplayPrecipitation } from '../../utils/WeatherUtils';
import { weatherCodesMap } from '../Forecast/WeatherIcons';
import { DailyForecast, HourlyForecast } from '../../types/Forecast';
import './DailyForecast.css';
import { useNavigate } from 'react-router-dom';
import ActionsButton from '../common/Actions';
import { AreaIdName } from '../../types/Area';
import { useRecoilValue } from 'recoil';
import { unitPreferencesState, precipUnitSelector, temperatureUnitSelector } from '../../state/unitPreferencesState';
import Tooltip from '../common/Tooltip';
import { getDisplayTime } from '../../utils/DateUtil';
import { _getCompareLineData, createUniqueLabels, createUrlSubdomain, lineColors, sortDisplayDates } from '../../utils/CommonUtils';
import LineChartCard from './LineChartCard';
import { LineChartData } from '../../types/LineChart';
import { FaSnowflake } from 'react-icons/fa6';

type DailyForecastCardProps = {
    forecast: DailyForecast;
    id: string;
    areaName: string;
    displayAreaName?: boolean;
    displayAreaLink?: boolean;
    displayDate?: boolean;
    bestTimeToClimb?: HourlyForecast;
    hourlyForecasts?: HourlyForecast[];
    overImage?: boolean;
    onRemoveCrag?: (event: any, areaIdName: AreaIdName) => void;
    className?: string;
    onClick?: (value: string | number) => void;
    displayActionsButton?: boolean;
    card?:any;
};

export default function DailyForecastCardComponent({card,forecast, areaName, displayAreaName = false, displayDate = true, displayAreaLink = false, id, onRemoveCrag, hourlyForecasts, overImage=false, onClick = () => { return; }, displayActionsButton = false, className = '' }: DailyForecastCardProps) {
    const dependentClass = overImage ? 'daily-over-image' : 'card-layout';
    const minHeight = displayAreaName ? '30rem' : '20rem';
    const navigate = useNavigate();
    const {metricUnits} = useRecoilValue(unitPreferencesState);
    const temperatureUnit = useRecoilValue(temperatureUnitSelector);
    const precipUnit = useRecoilValue(precipUnitSelector);
    const uniqueLabels: string[] = hourlyForecasts ? createUniqueLabels(hourlyForecasts, 'displayTime') : [];
    const labels:string[] = hourlyForecasts ? sortDisplayDates(uniqueLabels) : [];
    const lineData: LineChartData[] = hourlyForecasts ? _getCompareLineData(hourlyForecasts, 'climbit', 'climbitStars', undefined, 'displayTime', labels, lineColors.climbitScoreLineColor) : [];
    return(
        <div ref={card} className={`${dependentClass} ${className} min-w-[16rem] min-h-[${minHeight}] relative`} key={`daily-forecast-${forecast.areaId}-${forecast.time}`} onClick={(e) => { e.stopPropagation(); onClick(id); }}>
            {displayActionsButton && (
                <ActionsButton className='compare-actions-btn' alignment='right-[2px] top-[8px]'>
                    {onRemoveCrag && (
                        <div className='font-semibold' onClick={(event) =>{ onRemoveCrag(event, {name: forecast.areaName, id: forecast.areaId, path: ''}); event.stopPropagation();}}>
                        Remove
                        </div>
                    )}
                    {displayAreaLink && 
                    <div className='font-semibold' onClick={(event) =>{ event.stopPropagation(); navigate(`/area/${id}/${createUrlSubdomain(areaName)}`);}}>
                        Area Page
                    </div>   
                    }        
                </ActionsButton>
            )}

            {displayDate && (
                <div className='card-date'>
                    <span>{forecast.displayDate}</span>
                </div>
            )}

            {displayAreaName && areaName &&  (
                <div className='card-title mx-3 flex items-center'>
                    <span className='line-clamp-2 block'>{areaName}</span>
                </div>
            )}

            {(forecast && forecast.weatherCodes) && (
                <div className='w-full'>
                    {(forecast.weatherCodes.am && forecast.weatherCodes.pm) && (
                        <Tooltip text={`Sunrise: ${getDisplayTime(new Date(forecast?.sunrise), true)} Sunset: ${getDisplayTime(new Date(forecast?.sunset), true)}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                            <div className='flex w-full'>
                                <div className='flex flex-row justify-evenly w-full items-center'>
                                    <div className='flex flex-col justify-center items-center'>
                                        <div className='weather-icon-position'>
                                            {weatherCodesMap(true, 70).get(forecast.weatherCodes.am.code.toString())}
                                        </div>
                                        <p>AM</p>
                                    </div>
                                    <div className='flex flex-col justify-center items-center'>
                                        <div className='weather-icon-position'>
                                            {weatherCodesMap(true, 70).get(forecast.weatherCodes.pm.code.toString())}
                                        </div>
                                        <p>PM</p>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    )}
                    {(!forecast.weatherCodes.am && !forecast.weatherCodes.pm) && (
                        <Tooltip text={`Sunrise: ${getDisplayTime(new Date(forecast?.sunrise), true)} Sunset: ${getDisplayTime(new Date(forecast?.sunset), true)}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                            <div className='flex justify-center items-center flex-col'>
                                <div className='weather-icon-position'>
                                    {weatherCodesMap(true, 70).get(forecast?.weatherCodes.overall.code.toString())}
                                </div>
                            </div>
                        </Tooltip>
                    )}
                </div> 
            )}

           
            <Tooltip text={`Feels Like ${Math.round(forecast.maxApparentTemperature)}${temperatureUnit}/${Math.round(forecast.minApparentTemperature)}${temperatureUnit}`} className='text-sm text-center w-full' position='-top-1 -mt-8'>
                <div className='temperature-div mt-2'>
                    <div className='temperature-high-low'>
                        <BsThermometerHigh size={30} />
                        {Math.round(forecast.maxTemperature)}{temperatureUnit}
                    </div>
                    <div className='temperature-high-low'>
                        <BsThermometerLow size={30} />
                        {Math.round(forecast.minTemperature)}{temperatureUnit}
                    </div>
                </div>
            </Tooltip>

            <div className='rain-wind-sun-rise-down-div pt-2'>
                <div className='icon-styling'>
                    {((forecast.rainfallSum > 0 && !forecast.snowfallSum) || (!forecast.rainfallSum && !forecast.snowfallSum)) && (
                        <>
                            <WiRaindrop size={24} />{getDisplayPrecipitation(forecast?.rainfallSum, metricUnits)}{precipUnit} | {forecast?.precipitationHours} hour(s)
                        </>
                    )}
                    {forecast.snowfallSum > 0 && !forecast.rainfallSum && (
                        <>
                            <FaSnowflake size={12} className='mr-1'/>{getDisplayPrecipitation(forecast?.snowfallSum, metricUnits)}{metricUnits ? 'cm' : precipUnit} | {forecast?.precipitationHours} hour(s)
                        </>
                    )}
                    {forecast.snowfallSum > 0 && forecast.rainfallSum > 0 && (
                        <div className='whitespace-nowrap flex justify-center items-center'>
                            <WiRaindrop size={25} />{getDisplayPrecipitation(forecast?.rainfallSum, metricUnits)}{precipUnit} | <FaSnowflake size={12} className='mx-1' />{getDisplayPrecipitation(forecast?.snowfallSum, metricUnits)}{metricUnits ? 'cm' : precipUnit} | {forecast?.precipitationHours}hr(s)
                        </div>
                    )}
                </div>
            </div>

            {hourlyForecasts && (
                <div className='max-w-full min-w-full p-1'>
                    <hr className="h-0.5 w-full mt-3 border-t-0 bg-gray-medium" />
                    <div className="flex w-full mt-2">
                        {lineData && labels && (
                            <LineChartCard
                                labels={labels}
                                title='Climbit Score'
                                lineData={lineData}  
                                yMin={0}
                                yMax={5}
                                maintainAspectRatio={false}
                                height='h-[11rem]'
                                shadow={false}
                                legend={false}
                                paddingClass=''
                                labelSuffix='★'
                            />
                        )} 
                    </div>
                </div>  
            )}
        </div>
    );
}
