interface TooManyRequestsComponentProps {
    showGoHomeButton?: boolean;
    className?: string;
  }

export default function TooManyRequestsComponent({ showGoHomeButton = true, className }: TooManyRequestsComponentProps) {
    const targetClassName = className ?? 'flex items-center justify-center w-screen h-screen bg-gradient-to-r from-primary to-primary-dark md:px-20 p-4';
    return <div
        className= {targetClassName}
    >
        <div className="md:px-40 px-5 md:py-20 py-5 bg-white rounded-md shadow-xl">
            <div className="flex flex-col items-center">
                <h6 className="mb-2 text-2xl font-bold text-center text-gray md:text-3xl">
                    <span className="text-red">Oops!</span> Too Many Requests
                </h6>

                <p className="mb-8 text-center text-gray md:text-lg">
                    You have made too many requests in the last minute. Please try again after another minute has elapsed
                </p>
                {showGoHomeButton && (
                    <a
                        href="/"
                        className="px-6 py-2 text-sm font-semibold text-white bg-primary hover:bg-primary-dark"
                    >Go home
                    </a>
                )}
            </div>
        </div>
    </div>;

}



